.partybankform-ui{    
    .btnstyle-ui{
        color: white;
        background-color: #007d3b;
        border-radius: 5px;
    }
   
   // MobileView
   @media only screen and (max-width: 599px) {
    .deskview{
        display: none;
    } 
    .tabview{
        display: none;
    }
    .title-font {
        font-size: 14px;
        font-weight: bold; 
        background-color: #007d3b; 
        color: white;  
        padding: 2px;
        margin-top: -22px;             
    } 
    .card-ui{
        font-size: 14px;        
    }
    .cardrow-ui{
        font-size: 16px;  
        font-weight: 500;      
    }    
}

// TabView
@media only screen and (min-width: 600px) and (max-width: 768px) {
    .mobileview{
        display: none;
    }
    .deskview{
        display: none;
    }         
    .title-font {
        font-size: 14px;
        font-weight: bold; 
        background-color: #007d3b; 
        color: white;  
        padding: 4px;
        margin-top: -15px;              
      } 
      .card-ui{
        font-size: 17px;        
    }
    .cardrow-ui{
        font-size: 18px;  
        font-weight: 500;      
    }    
}    

// DesktopView
@media only screen and (min-width: 769px) {
    .mobileview{
        display: none;
    } 
    .tabview{
        display: none;
    }
    .title-font {
        font-size: 14px;
        font-weight: bold; 
        background-color: #007d3b; 
        color: white;
        padding: 2px; 
        margin-top: -15px;       
      }
}
    .nxtbill {
        font-size: 10px;
    }
}
