.buttonColor {
  background-color: #007d3b;
}
.logo {
  height: 50px;
  background: #007d3b;
  width: 100px;
  display: flex;
  text-align: center;
}
img {
  width: 60%;
}
.align-name {
  text-align: center;
  margin-left: 35px;
  font-size: 20px;
  color: white;
  }
  .error {
    color: red;
    font-size: 14px;
    margin-left: 15px;
  }