.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float-right {
  float: right !important;
}

.pointer {
  cursor: pointer !important;
}


/* Content Area Styling */
.content-container {
  padding-top: 78px; 
  background-color: white;
  position: relative;
  z-index: 0; 
}

.address-container{
  padding-top: 10px; 
  background-color: white;
  position: relative;
  z-index: 0; 
}

.title-font {
  font-size: 14px;
  font-weight: bold; 
  background-color: #007d3b; 
  color: white;
}
.search {
  position: 'relative';
  margin-left: 22px;
  text-align: left; 
}
.button-align{
  vertical-align: -webkit-baseline-middle;
}
.table-head{
  background-color: #007d3b !important;
  color: white !important;
}
.table-font{
  font-size: 12px !important;   
}
.mobileno-color {
  color: #007d3b;
  font-weight: bold;
}
.bottom-info {
  float: left; 
}
.card-style{
  /* background-color: grey; */
  color: grey;
  font-size: 14px;
}
.bold-text {
  font-weight: bold;
  font-family: sans-serif;
  font-size: 14px;
}
.customer-title {
  background-color: #007d3b; 
  color: white;
}
.customer-prod-title{
  font-size: 14px;
  font-weight: bold;
}
.customer-detail-font{
  font-size: 12px;
  color: white;
}
