.buttonColor {
  background-color: #007d3b;
}
.logo {
  background: #007d3b;
  width: 100px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  // margin-top: 5px;
  // margin-bottom: 5px;
}
img {
  width: 50%;
}
.titlestyle {
  width:100%;
  font-size: 20px;
  text-align: right;
 
  color: white;
}
.headerheight {
  height: 60px;
}


.drawer-leftmenu {
  position: relative;
  z-index: 3;
    .MuiDrawer-paper {
      padding-top: 57px;
      width: 240px !important;
      overflow: hidden;   
  }
}

.AppBar{
  position: fixed;
  z-index: 4;
}
