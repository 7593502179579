
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate-end-p1 {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes rotate-end-p2 {
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

/* Stroke animations */
@-webkit-keyframes init-stroke {
  0% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 40;
  }
}
@keyframes init-stroke {
  0% {
    stroke-dashoffset: 240;
  }
  100% {
    stroke-dashoffset: 40;
  }
}

@-webkit-keyframes stroke-end {
  0% {
    stroke-dashoffset: 40;
  }
  100% {
    stroke-dashoffset: 240;
  }
}

@keyframes stroke-end {
  0% {
    stroke-dashoffset: 40;
  }
  100% {
    stroke-dashoffset: 240;
  }
}

// Attachment

.bx--loading {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 690ms;
          animation-duration: 690ms;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  width: 10.5rem;
  height: 10.5rem;
}

.bx--loading svg circle {
  -webkit-animation-name: init-stroke;
          animation-name: init-stroke;
  -webkit-animation-duration: 10ms;
          animation-duration: 10ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
          animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
}

.bx--loading__svg {
  fill: transparent;
}

.bx--loading__svg circle {
  stroke-width: 8;
  stroke-linecap: butt;
  stroke-dasharray: 240;
}

.bx--loading__stroke {
  stroke: #0f62fe;
  stroke-dashoffset: 40;
}

.bx--loading--small .bx--loading__stroke {
  stroke-dashoffset: 99;
}

.bx--loading--stop {
  -webkit-animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards;
          animation: rotate-end-p1 700ms cubic-bezier(0.2, 0, 1, 0.9) forwards, rotate-end-p2 700ms cubic-bezier(0.2, 0, 1, 0.9) 700ms forwards;
}

.bx--loading--stop svg circle {
  -webkit-animation-name: stroke-end;
          animation-name: stroke-end;
  -webkit-animation-duration: 700ms;
          animation-duration: 700ms;
  -webkit-animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
          animation-timing-function: cubic-bezier(0.2, 0, 1, 0.9);
  -webkit-animation-delay: 700ms;
          animation-delay: 700ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

.bx--loading--small {
  width: 2rem;
  height: 2rem;
}

.bx--loading--small circle {
  stroke-width: 12;
}

.bx--loading--small .bx--loading__svg {
  stroke: #0f62fe;
}

.bx--loading__background {
  stroke: #e0e0e0;
  stroke-dashoffset: 0;
}

.bx--loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 6000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(22, 22, 22, 0.5);
  -webkit-transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
  transition: background-color 720ms cubic-bezier(0.4, 0.14, 0.3, 1);
}

.bx--loading-overlay--stop {
  display: none;
}

.bx--file {
  width: 100%;
}

.bx--file--invalid {
  margin-right: 0.5rem;
  fill: #da1e28;
}

.bx--file--label {
  font-size: 12px !important;
    position: inherit;
    transform: inherit;
    margin-bottom: 5px;
    color: #808080 !important;
	font-weight: 400;
	display: flex;
}

.bx--form-item input{
	color: #111111;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #d9e1df;
	padding: 6px 16px;
    font-size: 0.875rem;
	border-radius: 4px;
    letter-spacing: 0.02857em;
	cursor: pointer;
	border: 0;
	display: inline-flex;
	font-weight: 500;
}
.bx--btn--primary {
    color: var(--cds-text-04,#fff);
    background-color: var(--cds-interactive-01,#0f62fe);
    border: 1px solid transparent;
}
.bx--btn {
	font-size: 14px !important;
	color: #111111 !important;
    box-sizing: border-box;
    font-family: inherit;
    vertical-align: initial;
    border: 0;
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 6px 16px;
    text-decoration: none;
    outline: none;
    cursor: pointer;
    transition: background 70ms cubic-bezier(0,0,.38,.9),box-shadow 70ms cubic-bezier(0,0,.38,.9),border-color 70ms cubic-bezier(0,0,.38,.9),outline 70ms cubic-bezier(0,0,.38,.9);
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    background-color: #d9e1df;
	border-radius: 5px;
}
.bx--visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    visibility: inherit;
    clip: rect(0,0,0,0);
}

.bx--file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  visibility: inherit;
  clip: rect(0, 0, 0, 0);
}

.bx--file-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin: 0;
  padding-right: 4rem;
}

.bx--file-browse-btn {
  display: inline-block;
  width: 100%;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  color: #0f62fe;
  outline: 2px solid transparent;
  outline-offset: -2px;
  cursor: pointer;
  -webkit-transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
  transition: 110ms cubic-bezier(0.2, 0, 0.38, 0.9);
}

.bx--file-browse-btn:focus, .bx--file-browse-btn:hover {
  outline: 2px solid #0f62fe;
}

.bx--file-browse-btn:hover, .bx--file-browse-btn:focus, .bx--file-browse-btn:active, .bx--file-browse-btn:active:visited {
  text-decoration: underline;
}

.bx--file-browse-btn:active {
  color: #161616;
}

.bx--file-browse-btn--disabled {
  color: #c6c6c6;
  text-decoration: none;
  cursor: no-drop;
}

.bx--file-browse-btn--disabled:hover, .bx--file-browse-btn--disabled:focus {
  color: #c6c6c6;
  text-decoration: none;
  outline: none;
}

.bx--file-browse-btn--disabled .bx--file__drop-container {
  border: 1px dashed #f4f4f4;
}

.bx--label-description {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0.16px;
  margin-bottom: 1rem;
  color: #525252;
	display: none;
}

.bx--file-btn ~ .bx--file-container {
  margin-top: 1.5rem;
}

.bx--btn ~ .bx--file-container {
  margin-top: 1rem;
}

.bx--form-item {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}


.bx--file .bx--file-container,
.bx--file ~ .bx--file-container {
  margin-top: 0.5rem;
}

.bx--file__selected-file {
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  word-break: break-word;
  background-color: #daf1ff;
	border-radius: 5px;
    height: 32px;
    min-height: 32px !important;
}

.bx--file__selected-file:last-child {
  margin-bottom: 0;
}

.bx--file__selected-file .bx--form-requirement {
  grid-column: 1 / -1;
  max-height: none;
  margin: 0;
}

.bx--file__selected-file .bx--loading {
  width: 2rem;
  height: 2rem;
  margin-right: -0.5rem;
}

.bx--file__selected-file .bx--inline-loading__animation .bx--loading {
  margin-right: 0;
}

.bx--file__selected-file .bx--file-filename {
  font-size: 0.875rem;
  font-size: 13px;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bx--file__selected-file--field {
  min-height: 2.5rem;
}

.bx--file__selected-file--sm {
  min-height: 2rem;
}

.bx--file__selected-file--invalid__wrapper {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  max-width: 20rem;
  margin-bottom: 0.5rem;
  background-color: #f4f4f4;
  outline-width: 1px;
}

.bx--file__selected-file--invalid {
  outline: 2px solid #da1e28;
  outline-offset: -2px;
  padding: 1rem 0;
}

.bx--file__selected-file--invalid .bx--form-requirement {
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;
}

.bx--file__selected-file--invalid
.bx--form-requirement__title,
.bx--file__selected-file--invalid
.bx--form-requirement__supplement {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  padding: 0 1rem;
}

.bx--file__selected-file--invalid
.bx--form-requirement__supplement {
  color: #161616;
}

.bx--file__selected-file--invalid + .bx--form-requirement {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0.32px;
  display: block;
  max-height: 12.5rem;
  padding: 0.5rem 1rem;
  overflow: visible;
  color: #da1e28;
  font-weight: 400;
}

.bx--file__selected-file--invalid
+ .bx--form-requirement
.bx--form-requirement__supplement {
  padding-bottom: 0.5rem;
  color: #161616;
}

.bx--file__state-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-width: 1.5rem;
  padding: 0px;
}

.bx--file__state-container .bx--loading__svg {
  stroke: #161616;
}

.bx--file__state-container .bx--file-complete {
  cursor: pointer;
  fill: #0f62fe;
}

.bx--file__state-container .bx--file-complete:focus {
  outline: 1px solid #0f62fe;
}

.bx--file__state-container .bx--file-complete [data-icon-path='inner-path'] {
  opacity: 1;
  fill: #ffffff;
}

.bx--file__state-container .bx--file-invalid {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  fill: #da1e28;
}

.bx--file__state-container .bx--file-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 1.4rem;
  height: auto;
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
  fill: #161616;
  margin-right: 5px;
	
	.MuiSvgIcon-root {
		fill: currentColor;
		width: 1em;
		height: 1em;
		display: inline-block;
		font-size: 1.4rem;
		transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		flex-shrink: 0;
		user-select: none;
	}
	.MuiChip-deleteIcon {
		color: #1976d2;
		width: 22px;
		cursor: pointer;
		height: 22px;
		margin: 0;
		-webkit-tap-highlight-color: transparent;
	}
}

.bx--file__state-container .bx--file-close:focus {
  outline: none;
}

.bx--file__state-container .bx--file-close svg path {
  fill: #1976d2;
}

.bx--file__state-container .bx--inline-loading__animation {
  margin-right: -0.5rem;
}

.bx--file__drop-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  height: 6rem;
  padding: 1rem;
  overflow: hidden;
  border: 1px dashed #8d8d8d;
}

.bx--file__drop-container--drag-over {
  background: none;
  outline: 2px solid #0f62fe;
  outline-offset: -2px;
}